<template>
    <div class="sra transitions pa-20 b-box">
      <div class="row pa-20 weight-600 font-18">Admin Portal</div>
      <hr />
      <div class="col pa-10">
        <router-link @click.native="meeting" to="/admin/dashboard" class="btn align-center gap-10">
          <s-icon color='white'>view-dashboard-outline</s-icon> <span>Dashboard</span>
        </router-link>
        <router-link @click.native="meeting" to="/admin/partner" class="btn align-center gap-10">
          <s-icon width="16" color='white'>handshake</s-icon> <span>Partners</span>
        </router-link>
        <router-link @click.native="meeting" to="/admin/customers" class="btn align-center gap-10">
          <s-icon color='white'>account-group-outline</s-icon> <span>Customers</span>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  
  export default {
    components: {
  
    },
    methods: {
      meeting() {
        this.$store.commit("toolbars/toggleSidebar");
      },
    },
    computed: {
      ...mapState('user', ['role'])
    },
  };
  </script>
  
  <style scoped lang="less">
  .sra {
    min-width: 280px;
    width: 280px;
    height: 100%;
    background-color: #37424d; //#37424d;
    overflow-y: auto;
    overflow-y: overlay;
  }
  
  .text {
    width: 280px;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    color: #ffffff;
    text-align: center;
  }
  
  .active {
    opacity: 1;
  }
  
  .normal {
    white-space: normal;
  }
  
  .btn {
    padding: 15px;
    transition: 0.2s all ease;
  }
  
  a {
    color: white;
  }
  
  img {
    min-width: 24px;
    min-height: 24px;
    transition: 0.2s all ease;
  }
  
  span {
    transition: 0.1s all ease;
  }
  
  .router-link-active img,
  .btn:focus img {
    filter: invert(53%) sepia(42%) saturate(512%) hue-rotate(104deg) brightness(88%) contrast(85%);
  }
  
  .router-link-active span,
  .btn:focus span {
    filter: invert(53%) sepia(42%) saturate(512%) hue-rotate(104deg) brightness(88%) contrast(85%);
  }
  
  .btn:hover {
    background-color: hsla(0, 0%, 100%, 0.1);
    border-radius: 5px;
  }
  
  .router-link-active {
    background-color: hsla(0, 0%, 100%, 0.05);
    border-radius: 5px;
    color: var(--green);
  }
  
  .btn:focus-visible {
    background-color: hsla(0, 0%, 100%, 0.1);
    outline: none;
  }
  
  @media only screen and (max-width: 768px) {
    .sra {
      height: calc(100% - 60px);
    }
  }
  </style>
  