<template>
  <div class="sidebar pa-0">
    <transition name="slide-right">
      <SRA
        v-if="combine && !isReseller && !isAdmin"
        class="bigCollapse"
        v-outside="close"
      />
    </transition>
    <transition name="slide-right">
      <ResellerSRA
        v-if="combine && isReseller"
        class="bigCollapse"
        v-outside="close"
      />
    </transition>
    <transition name="slide-right">
      <AdminSidebar
        v-if="combine && isAdmin"
        class="bigCollapse"
        v-outside="close"
      />
    </transition>
  </div>
</template>

<script>
import SRA from "@/components/Toolbars/SRA.vue";
import AdminSidebar from "@/components/Toolbars/AdminSidebar.vue";
import ResellerSRA from "@/components/Toolbars/ResellerSRA.vue";
import LeftToolbar from "@/components/Toolbars/LeftToolbar.vue";
import { mapState } from "vuex";
export default {
  components: {
    SRA,
    ResellerSRA,
    LeftToolbar,
    AdminSidebar,
  },
  methods: {
    close() {
      this.$store.commit("toolbars/toggleSidebarControl", false);
    },
  },
  computed: {
    combine() {
      return this.$screen.md || this.sidebar;
    },
    isReseller() {
      return this.$route.path.includes("partners");
    },
    isAdmin() {
      return this.$route.path.includes("admin");
    },
    ...mapState("user", ["email"]),
    ...mapState("toolbars", ["sidebar"]),
  },
  watch: {
    "$screen.width"() {
      if (!this.$screen.md) this.close();
    },
  },
  mounted() {
    this.popupItem = document.getElementsByClassName("mobile_chevron")[0];
    this.close();
  },
};
</script>



<style scoped lang="less">
.sidebar {
  display: flex;
  z-index: 3;
  height: calc(100vh - var(--top-toolbar-height) - var(--footer-height));
}

*::-webkit-scrollbar-thumb {
  background-color: #cfd0d100;
  border-radius: 0px;
}

@media only screen and (max-width: 768px) {
  .bigCollapse {
    position: absolute;
    z-index: 3;
  }

  .sideBar {
    min-width: 60px;
  }
}

@media only screen and (max-height: 390px) {
  .sidebar {
    overflow: overlay;
  }
}

@media only screen and (max-width: 576px) {
  .smallCollapse {
    transform: translateX(-60px);
    transition: all ease 0.2s;
    position: absolute;
    height: 100%;
    z-index: 3;
  }

  // .bigCollapse {
  //   transform: translateX(-355px);
  //   transition: all ease 0.2s;
  //   position: absolute;
  //   height: 100%;
  //   z-index: 3;
  // }
}
</style>
