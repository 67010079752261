<template>
  <div class="toolbar">
    <div class="white-overlay">
      <div class="top" @mouseleave="changeOut()">
        <button
          @click="select(item)"
          v-for="(item, index) in top"
          @mouseover="mouseOnHover = true"
          @mouseleave="mouseOnHover = false"
          :key="index"
          class="item bg relative uppercase"
          :class="{
            active: $route.path == item.to || $route.name == item.name,
            greenBg: item.type == 'button',
          }"
        >
          <div class="sr">{{ item.title }}</div>
          <div class="h100 w100 center" @mouseover="change(index)">
            <s-icon color="grey">{{ item.icon }}</s-icon>
            <div
              v-if="hover == index && showHover"
              class="hover center px-10"
              :class="{ greenBg: item.type == 'button' }"
            >
              {{ item.title }}
            </div>
          </div>
        </button>
      </div>
      <div class="bottom" @mouseleave="changeOut()">
        <button
          @click="select(item)"
          @mouseover="mouseOnHover = true"
          @mouseleave="mouseOnHover = false"
          v-for="(item, index) in bottom"
          :key="index"
          class="item bg-bottom relative uppercase"
          :class="{
            active: $route.path == item.to || $route.name == item.name,
          }"
        >
          <div class="sr">{{ item.title }}</div>
          <div class="h100 w100 center" @mouseover="change(top.length + index)">
            <s-icon color="grey">{{ item.icon }}</s-icon>
            <div
              v-if="hover == top.length + index && showHover"
              class="hover center px-10"
            >
              {{ item.title }}
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      hover: 1,
      mouseOnHover: false,
      showHover: false,
    };
  },
  methods: {
    select(val) {
      if (val.type == "button") this.$store.commit(`modals/${val.to}`, true);
      let isCurrent = val.to == this.$route.path;
      if (!isCurrent && val.type == "route") this.$router.push(val.to);
    },
    async change(val) {
      this.hover = val;
      await this.$wait(1000);
      if (this.hover >= -2) this.showHover = true;
    },
    async changeOut(val) {
      this.showHover = false;
      await this.$wait(50);
      if (this.mouseOnHover) return;
      this.hover = -1;
      this.showHover = false;
    },
  },
  computed: {
    top() {
      return [
        {
          type: "route",
          icon: "home",
          to: "/",
          title: this.$t("toolbars.leftToolBar.dashboard"),
        },
      ];
    },
    bottom() {
      return [
        {
          type: "button",
          icon: "information-outline",
          to: "appInfo",
          title: this.$t("toolbars.drawer.info"),
        },
      ];
    },
  },
};
</script>

<style scoped lang="less">
.hover {
  position: absolute;
  right: -2px;
  top: 0;
  transform: translateX(100%);
  background-color: #697682;
  width: 200px;
  min-height: 100%;
  z-index: 3;
  color: white;
  box-sizing: border-box;
}

.toolbar {
  width: 60px;
  min-width: 60px;
  height: 100%;
  background-image: var(--gradient);
}

.white-overlay {
  background-color: rgba(255, 255, 255, 0.1);
  width: 60px;
  min-width: 60px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  margin-bottom: 1px;
  color: #ffffff2a;
}

.bg {
  background-color: rgba(255, 255, 255, 0.1);
}

.greenBg {
  background-color: #409875 !important;
}

.bg:hover,
.bg:focus,
.bg-bottom:hover,
.bg-bottom:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

.bg-bottom:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

.bg:hover .sidebar {
  opacity: 1;
}

.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar {
  opacity: 0.4;
}

*::-webkit-scrollbar-thumb {
  background-color: #cfd0d100;
  border-radius: 0px;
}

@media only screen and (max-height: 355px) {
  .white-overlay {
    height: initial;
  }

  .toolbar {
    height: initial;
  }
}
</style>
