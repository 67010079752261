<template>
  <div
    v-resize
    @resize="onResize"
    ref="footer"
    class="footer transitions"
  ></div>
</template>

<script>
export default {
  methods: {
    onResize() {
      let bar = getComputedStyle(this.$refs.footer).height;
      let root = document.documentElement;
      root.style.setProperty("--footer-height", bar);
    },
  },
};
</script>

<style scoped lang="less">
.footer {
  height: 40px;
  background-color: var(--almost-black);
  z-index: 2;
}

@media only screen and (max-width: 576px), screen and (max-height: 576px) {
  .footer {
    height: 0;
    min-height: 0;
  }
}
</style>
