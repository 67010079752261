<template>
  <div class="sra col transitions pa-20 b-box">
    <div class="row pa-20 weight-600 font-18">Customer Portal</div>
    <hr />
    <div class="col pa-10 grow">
      <router-link @click.native="meeting" to="/licenses" class="btn align-center gap-10">
        <s-icon color='white'>file-document-outline</s-icon> <span>Licenses</span>
      </router-link>
      <router-link @click.native="meeting" to="/support" class="btn align-center gap-10">
        <s-icon height="16" color='white'>ticket-confirmation-outline</s-icon> <span>Support tickets</span>
      </router-link>
      <router-link @click.native="meeting" to="/profile" class="btn align-center gap-10">
        <s-icon color='white'>account-outline</s-icon> <span>Profile</span>
      </router-link>
      <router-link @click.native="meeting" to="/invoices" class="btn align-center gap-10">
        <s-icon color='white'>receipt-outline</s-icon> <span>Invoices</span>
      </router-link>
      <router-link @click.native="meeting" to="/billing" class="btn align-center gap-10">
        <s-icon color='white'>credit-card-outline</s-icon> <span>Billing Information</span>
      </router-link>
      <router-link @click.native="meeting" to="/downloads" class="btn align-center gap-10">
        <s-icon color='white'>download</s-icon> <span>Downloads</span>
      </router-link>

    </div>
    <button @click="openModal"  class="btn align-center gap-10">
      <s-icon color='white'>information-outline</s-icon> <span>Information</span>
    </button>
  </div>
</template>

<script>

export default {
  components: {

  },
  methods: {
    change(change) {
      this.$store.commit("toolbars/changeSRA", change);
    },
    meeting() {
      this.$store.commit("toolbars/toggleSidebar");
    },
    openModal(){
      this.$store.commit("modals/appInfo", true);
      this.meeting()
    }
  },
  computed: {
    state() {
      return this.$store.state.toolbars.sra;
    },
  },
};
</script>

<style scoped lang="less">
.sra {
  min-width: 280px;
  width: 280px;
  height: calc(100vh - 100px);
  background-color: #37424d;
  overflow-y: auto;
  overflow-y: overlay;
}

.text {
  width: 280px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: #ffffff;
  text-align: center;
}

.active {
  opacity: 1;
}

.normal {
  white-space: normal;
}

.btn {
  padding: 15px;
  transition: 0.2s all ease;
}

a {
  color: white;
}

img {
  min-width: 24px;
  min-height: 24px;
  transition: 0.2s all ease;
}

span {
  transition: 0.1s all ease;
}

.router-link-active img,
.btn:focus img {
  filter: invert(53%) sepia(42%) saturate(512%) hue-rotate(104deg) brightness(88%) contrast(85%);
}

.router-link-active span,
.btn:focus span {
  filter: invert(53%) sepia(42%) saturate(512%) hue-rotate(104deg) brightness(88%) contrast(85%);
}

.btn:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 5px;
}

.router-link-active {
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 5px;
  color: var(--green);
}

.btn:focus-visible {
  background-color: hsla(0, 0%, 100%, 0.1);
  outline: none;
}

@media only screen and (max-width: 768px) {
  .sra {
    height: calc(100% - 60px);
  }
}
</style>
